
<div class="notification">
  <div class="notification_container">
    <div class="attention-mark" [ngClass]="classList()"></div>
    <img
      class="alert"
      src="/assets/img/icon/alerts-info-small-ico.svg"
      alt="info"
    />
    <p class="notification_text">
      {{ message() }}
    </p>
  </div>

  <!--TODO: может понадобится-->
  <!--<div class="close" >
    <img src="/assets/img/icon/close-ico-grey.svg" alt="close" />
  </div>-->
</div>
