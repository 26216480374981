<custody-dashboard-top-bar></custody-dashboard-top-bar>
<div class="app-container" [ngStyle]="contentHeight()">
  <div class="left-panel">
    <custody-navigation-container>
      <custody-navigation-item
        name="Неторговые операции"
        icon="/assets/img/icon/blockchain-ico.svg"
        [link]="['/', 'non-commerce']"
      ></custody-navigation-item>
      <custody-navigation-item
        name="Менеджер кошельков"
        icon="/assets/img/icon/blockchain-ico.svg"
        [link]="['/', 'wallet']"
      ></custody-navigation-item>
      <custody-navigation-item
        name="Настройки"
        icon="/assets/img/icon/blockchain-ico.svg"
        [link]="['/', 'settings']"
      ></custody-navigation-item>
    </custody-navigation-container>
  </div>
  <div class="app-body">
    <div class="w-100">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
