import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ComponentStore } from '@ngrx/component-store';
import { GlobalNotifyType } from '@store-common/global-notify-state/global-notify.model';

export interface GlobalNotifyStateModel {
  type: GlobalNotifyType;
  message: string;
}

const initialGlobalNotify: GlobalNotifyStateModel = {
  message: '',
  type: 'info',
};

@Component({
  selector: 'custody-global-notify-ui',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './global-notify-ui.component.html',
  styleUrls: ['./global-notify-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalNotifyUiComponent
  extends ComponentStore<GlobalNotifyStateModel>
  implements OnInit
{
  public message: Signal<string> = this.selectSignal(
    (state) => state?.message ?? ''
  );
  public classList: Signal<string[]> = this.selectSignal((state) => [
    state?.type ?? '',
  ]);

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: GlobalNotifyStateModel
  ) {
    super(initialGlobalNotify);
  }

  public ngOnInit(): void {
    this.data && this.patchState({ ...this.data });
  }
}
