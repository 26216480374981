import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'settings',
    loadChildren: () =>
      import('../modules/settings-page/settings-page.module').then(
        (m) => m.SettingsPageModule
      ),
  },
  {
    path: 'non-commerce',
    loadChildren: () =>
      import(
        '../modules/non-commerce-actions-page/non-commerce-actions-page.module'
      ).then((m) => m.NonCommerceActionsPageModule),
  },
  {
    path: 'wallet',
    loadChildren: () =>
      import('../modules/wallet-manager-page/wallet-manager-page.module').then(
        (m) => m.WalletManagerPageModule
      ),
  },
  {
    path: '**',
    redirectTo: 'wallet',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
