import { createReducer, on } from '@ngrx/store';
import { initialGlobalNotifyStore } from './global-notify.model';
import * as stateAction from './global-notify.action';

const globalNotifyReducer = createReducer(
  initialGlobalNotifyStore,
  on(stateAction.showMessage, (state, action) => ({
    ...state,
    message: action.payload.message,
    type: action.payload.type,
  })),
  on(stateAction.clearMessage, (state, action) => ({
    ...initialGlobalNotifyStore,
  }))
);

export const globalNotifyReducerFn = (state, action) =>
  globalNotifyReducer(state, action);
