import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import * as stateSelector from './global-notify.selector';
import { filter, Observable } from 'rxjs';
import * as stateAction from './global-notify.action';
import { GlobalNotifyStateModel } from '@store-common/global-notify-state/global-notify.model';

@Injectable()
export class GlobalNotifyFacade {
  public hasMessage: Signal<boolean> = this.store.selectSignal(
    stateSelector.hasMessage
  );
  public message$: Observable<GlobalNotifyStateModel> = this.store
    .select(stateSelector.getMessage)
    .pipe(filter((message) => !!message.message));

  constructor(private store: Store) {}

  public clear(): void {
    this.store.dispatch(stateAction.clearMessage());
  }
}
