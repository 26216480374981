import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
  Optional,
  signal,
  Signal,
} from '@angular/core';
import { NAVIGATION_CONTAINER_ELEMENT_TOKEN } from '@modules-ui/navigation-panel-ui/token/navigation-container.token';
import { NavigationContainerComponent } from '@modules-ui/navigation-panel-ui/components/navigation-container/navigation-container.component';

@Component({
  selector: 'custody-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemComponent implements OnInit {
  @Input()
  public name: string;

  @Input()
  public icon: string;

  @Input()
  public link: string[];

  @Input()
  public active: boolean;

  public maximized: Signal<boolean> = signal(true);

  constructor(
    @Optional()
    @Inject(NAVIGATION_CONTAINER_ELEMENT_TOKEN)
    private container: NavigationContainerComponent
  ) {}

  public ngOnInit(): void {
    if (this.container) {
      this.maximized = this.container.maximized;
    }
  }
}
