export const GLOBAL_NOTIFY_STATE_KEY = 'globalNotifyState';

export type GlobalNotifyType = 'warn' | 'info' | 'error';

export interface GlobalNotifyStateModel {
  message: string;
  type: GlobalNotifyType;
}

export const initialGlobalNotifyStore: GlobalNotifyStateModel = {
  message: '',
  type: 'info',
};
