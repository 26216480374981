import { ActionReducerMap } from '@ngrx/store';
import {
  GLOBAL_NOTIFY_STATE_KEY,
  GlobalNotifyStateModel,
} from './global-notify-state/global-notify.model';
import { globalNotifyReducerFn } from './global-notify-state/global-notify.reducer';
import {
  TOP_BAR_STATE_KEY,
  TopBarStateModel,
} from '@store-common/top-bar-store/top-bar.model';
import { topBarReducerFn } from '@store-common/top-bar-store/top-bar.reducer';

export const COMMON_STATE_KEY = 'commonState';

export interface CommonState {
  [GLOBAL_NOTIFY_STATE_KEY]: GlobalNotifyStateModel;
  [TOP_BAR_STATE_KEY]: TopBarStateModel;
}

export const commonStateReducerMap: ActionReducerMap<CommonState> = {
  [GLOBAL_NOTIFY_STATE_KEY]: globalNotifyReducerFn,
  [TOP_BAR_STATE_KEY]: topBarReducerFn,
};
