import { createSelector } from '@ngrx/store';
import { getCommonState } from '../common-store.selector';
import { GLOBAL_NOTIFY_STATE_KEY } from './global-notify.model';

const getState = createSelector(
  getCommonState,
  (state) => state[GLOBAL_NOTIFY_STATE_KEY]
);

export const getMessage = createSelector(getState, (state) => state);
export const hasMessage = createSelector(getState, (state) => !!state.message);
