import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationContainerComponent } from './components/navigation-container/navigation-container.component';
import { NavigationItemComponent } from './components/navigation-item/navigation-item.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [NavigationContainerComponent, NavigationItemComponent],
  imports: [CommonModule, RouterLink, RouterLinkActive, MatTooltipModule],
  exports: [NavigationContainerComponent, NavigationItemComponent],
})
export class NavigationPanelUiModule {}
