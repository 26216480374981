<div class="nav-container" [@roll]="maximized()">
  <div class="nav-header">
    <div class="wrapper">
      <div class="roll-button" [@spin]="minimized()" (click)="toggle()"></div>
    </div>

  </div>
  <div class="nav-body">
    <ng-content></ng-content>
  </div>
</div>

