<a
  [routerLink]="link"
  [routerLinkActive]="'active'"
  [routerLinkActiveOptions]="{ exact: false }"
  [matTooltip]="name"
  matTooltipPosition="above"
  [matTooltipDisabled]="maximized()"
>
  <div class="item">
    <img class="icon" [src]="icon" alt="panel-icon" />
    <div class="menu-label" *ngIf="maximized()">{{ name }}</div>
  </div>
</a>
