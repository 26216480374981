import { EventManager } from '@angular/platform-browser';
import { EMPTY } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

export class HostBinderAsyncPlugin {
  manager!: EventManager;

  supports(event: string): boolean {
    return event.startsWith('$.');
  }

  addEventListener(element: HTMLElement, event: string): () => void {
    element[event] = EMPTY;

    const method = this.getMethod(element, event);
    const sub = this.manager
      .getZone()
      .onStable.pipe(
        take(1),
        switchMap(() => element[event]),
      )
      .subscribe((value) => method(value));

    return () => sub.unsubscribe();
  }

  private getMethod(element: HTMLElement, event: string): (...attr) => void {
    const [, key, value, unit = ''] = event.split('.');

    if (event.endsWith('.attr')) {
      return (v) => element.setAttribute(key, String(v));
    }

    if (key === 'class') {
      return (v) => element.classList.toggle(value, !!v);
    }

    if (key === 'style') {
      return (v) => element.style.setProperty(value, `${v}${unit}`);
    }

    return (v) => (element[key] = v);
  }
}
