import { createSelector } from '@ngrx/store';
import { TOP_BAR_STATE_KEY } from '@store-common/top-bar-store/top-bar.model';
import { getCommonState } from '@store-common/common-store.selector';

const getStore = createSelector(
  getCommonState,
  (state) => state[TOP_BAR_STATE_KEY]
);

export const getTitle = createSelector(getStore, (state) => state.title);
