import { Action, createReducer, on } from '@ngrx/store';
import {
  initialTopBarStore,
  TopBarStateModel,
} from '@store-common/top-bar-store/top-bar.model';
import * as stateAction from './top-bar.action';

const topBarReducer = createReducer(
  initialTopBarStore,

  on(stateAction.setTitle, (state, acion) => ({
    ...state,
    title: acion.payload,
  }))
);

export const topBarReducerFn = (state: TopBarStateModel, action: Action) =>
  topBarReducer(state, action);
