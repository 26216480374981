import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Output,
  Signal,
} from '@angular/core';
import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ComponentStore } from '@ngrx/component-store';
import { NAVIGATION_CONTAINER_ELEMENT_TOKEN } from '@modules-ui/navigation-panel-ui/token/navigation-container.token';

interface NavigationContainerStateModel {
  maximized: boolean;
}

const initialNavigationContainerStore: NavigationContainerStateModel = {
  maximized: true,
};

@Component({
  selector: 'custody-navigation-container',
  templateUrl: './navigation-container.component.html',
  styleUrls: ['./navigation-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('roll', [
      state('true', style({ width: '*' })),
      state('false', style({ width: '40px' })),
      transition(
        'false <=> true',
        group([animate('200ms ease-in-out'), query('@spin', animateChild())])
      ),
    ]),
    trigger('spin', [
      state('true', style({ transform: '*' })),
      state('false', style({ transform: 'rotate(180deg)' })),
      transition('false <=> true', animate('300ms ease-in-out')),
    ]),
  ],
  providers: [
    {
      provide: NAVIGATION_CONTAINER_ELEMENT_TOKEN,
      useExisting: forwardRef(() => NavigationContainerComponent),
      multi: false,
    },
  ],
})
export class NavigationContainerComponent extends ComponentStore<NavigationContainerStateModel> {
  @Output()
  public onSizeChange: EventEmitter<void> = new EventEmitter<void>();

  public maximized: Signal<boolean> = this.selectSignal(
    (state) => state.maximized
  );
  public minimized: Signal<boolean> = this.selectSignal(
    (state) => !state.maximized
  );

  constructor() {
    super(initialNavigationContainerStore);
  }

  public toggle(): void {
    const state = this.get();

    this.patchState({ maximized: !state.maximized });
    this.onSizeChange.emit();
  }
}
