import { createAction, props } from '@ngrx/store';
import {
  GLOBAL_NOTIFY_STATE_KEY,
  GlobalNotifyType,
} from './global-notify.model';
import { GlobalNotifyStateModel } from '@modules-ui/global-notify-ui/global-notify-ui.component';

export const showMessage = createAction(
  `[${GLOBAL_NOTIFY_STATE_KEY}] show`,
  props<{ payload: GlobalNotifyStateModel }>()
);
export const clearMessage = createAction(`[${GLOBAL_NOTIFY_STATE_KEY}] clear`);
