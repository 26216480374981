import { NgModule } from '@angular/core';
import {
  BrowserModule,
  EVENT_MANAGER_PLUGINS,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import {
  COMMON_STATE_KEY,
  commonStateReducerMap,
} from '@store-common/common-store.model';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { HostBinderAsyncPlugin } from '../plugins/host-binder-async.plugin';
import { GlobalNotifyFacade } from '@store-common/global-notify-state/global-notify.facade';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DashboardTopBarComponent } from '@modules-ui/dashboard-top-bar/dashboard-top-bar.component';
import { NavigationPanelUiModule } from '@modules-ui/navigation-panel-ui/navigation-panel-ui.module';
import { TopBarFacade } from '@store-common/facade/top-bar.facade';
import { MatNativeDateModule } from '@angular/material/core';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { GlobalNotifyUiComponent } from '@modules-ui/global-notify-ui/global-notify-ui.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(COMMON_STATE_KEY, commonStateReducerMap),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(),
    MatSidenavModule,
    DashboardTopBarComponent,
    NavigationPanelUiModule,
    MatNativeDateModule,
    HttpClientModule,
    GlobalNotifyUiComponent,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: HostBinderAsyncPlugin,
      multi: true,
    },
    GlobalNotifyFacade,
    TopBarFacade,
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useValue: {
        backdropClass: ['backdrop-white'],
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
