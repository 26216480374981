import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarFacade } from '@store-common/facade/top-bar.facade';

@Component({
  selector: 'custody-dashboard-top-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dashboard-top-bar.component.html',
  styleUrls: ['./dashboard-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardTopBarComponent implements OnInit {
  public title: Signal<string>;

  constructor(private topBarFacade: TopBarFacade) {}

  public ngOnInit(): void {
    this.title = this.topBarFacade.title;
  }
}
