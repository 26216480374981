import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import * as stateAction from '@store-common/top-bar-store/top-bar.action';
import * as stateSelector from '@store-common/top-bar-store/top-bar.selector';

@Injectable()
export class TopBarFacade {
  public title: Signal<string> = this.store.selectSignal(
    stateSelector.getTitle
  );

  constructor(private store: Store) {}

  public setTitle(title: string): void {
    this.store.dispatch(stateAction.setTitle({ payload: title }));
  }
}
