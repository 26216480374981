import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  Signal,
} from '@angular/core';
import { GlobalNotifyFacade } from '@store-common/global-notify-state/global-notify.facade';
import { ComponentStore } from '@ngrx/component-store';
import { SafeStyle } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  GlobalNotifyStateModel,
  GlobalNotifyUiComponent,
} from '@modules-ui/global-notify-ui/global-notify-ui.component';
import { state } from '@angular/animations';
import { tap } from 'rxjs';

interface AppComponentStateModel {
  contentHeight: number;
}

const initialAppComponentStore: AppComponentStateModel = {
  contentHeight: 0,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent
  extends ComponentStore<AppComponentStateModel>
  implements AfterViewInit, OnInit
{
  public contentHeight: Signal<SafeStyle> = this.selectSignal((state) => ({
    'height.px': state.contentHeight,
  }));

  constructor(
    private globalNotify: GlobalNotifyFacade,
    private snackBar: MatSnackBar
  ) {
    super(initialAppComponentStore);
  }

  @HostListener('window:resize')
  public setHeight(): void {
    this.patchState({ contentHeight: window.innerHeight - 60 });
  }

  public ngAfterViewInit(): void {
    this.setHeight();
  }

  public ngOnInit(): void {
    this.effect(() =>
      this.globalNotify.message$.pipe(
        tap((message: GlobalNotifyStateModel) => {
          this.globalNotify.clear();

          this.snackBar.openFromComponent(GlobalNotifyUiComponent, {
            data: message,
            panelClass: ['global-notify'],
            verticalPosition: 'bottom',
            duration: 2000,
          });
        })
      )
    );
  }
}
